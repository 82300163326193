.fungi-item {
  table {
    width: 100%;
  }
  margin: 15px;
  img {
    max-height: 15vh;
    max-width: 15vw;
    margin-bottom: 5px;
  }
  margin-bottom: 5vh;
}

.fungi-list-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.field-label {
  width: 50%;
}
