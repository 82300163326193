
.main {
  width: 100%;
  padding: 15px;
}

body {
  padding-top: 80px;
}

.navbar-brand {
  margin-right: 20px !important;
}
